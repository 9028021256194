import React from "react";
import imgA1 from "../../assets/image/thumbnails/customerpremises.png";
import imgA2 from "../../assets/image/thumbnails/blue wave.png";
import imgA3 from "../../assets/image/thumbnails/affiliatepremises.png";
import imgA4 from "../../assets/image/thumbnails/remotenetwork.png";

const BusinessModels = () => {
  return (
    <>
      {/* <!-- Pricing Area -->  */}
      <div id="bmodels" className="pricing-area">
        <div className="container pt-12 pt-lg-24 pb-13 pb-lg-25">
          <div className="row justify-content-center">
            <div
              className="col-xxl-10 col-lg-10 col-md-10 "
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              {/* <!-- section-title start --> */}
              <div className="section-title text-center mb-12 pb-lg-15 pb-0">
                <h2 className="mb-9">
                  Business Models
                </h2>
                <p className="text-default-color text-left font-size-4 px-5 ">
                  We provide effective and very engaging business models to ensure transparancy, smooth progress and definite success of your projects.
                </p>
              </div>
              {/* <!-- section-title end --> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-10 col-xl-11">
              <div className="row justify-content-center">
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{minHeight:"440px"}}>
                      <img src={imgA1} className="card-img-top" style={{ backgroundColor: "#06213e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-4">
                          Resourcing at your premises
                        </h6>
                      </div>
                      <h2 className="mt-8 text-dodger">
                        <span className="text-left font-size-4 text-smoke font-weight-normal px-2">
                          Deployment on your inhouse managed projects either as resource pool or completely sourced fixed projects
                        </span>{" "}
                      </h2>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{minHeight:"440px"}}>
                      <img src={imgA2} className="card-img-top" style={{ backgroundColor: "#06213e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-4">
                          Complete execution in <span style={{whiteSpace: "nowrap"}}>e-cto.com</span> premises
                        </h6>
                      </div>
                      <h2 className="mt-8 text-dodger">
                        <span className="text-left font-size-4 text-smoke font-weight-normal px-2">
                          Near shore or offshore or remote execution in our premises with dedicated infrastructure and possible BOT models
                        </span>{" "}
                      </h2>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{minHeight:"440px"}}>
                      <img src={imgA3} className="card-img-top" style={{ backgroundColor: "#06213e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-4">
                          Execution in Affiliates premises
                        </h6>
                      </div>
                      <h2 className="mt-8 text-dodger">
                        <span className="text-left font-size-4 text-smoke font-weight-normal px-2">
                          Execution in affiliates premises with e-cto program management
                        </span>{" "}
                      </h2>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{minHeight:"440px"}}>
                      <img src={imgA4} className="card-img-top" style={{ backgroundColor: "#06213e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-4">
                          Global remote work
                        </h6>
                      </div>
                      <h2 className="mt-8 text-dodger">
                        <span className="text-left font-size-4 text-smoke font-weight-normal px-2">
                          Managed by e-cto.com of well connected network of developers and integrators
                        </span>{" "}
                      </h2>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessModels;
