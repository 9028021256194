import React from "react";
import { Link } from "gatsby";

const Customers = () => {
  return (
    <>
      {/* <!-- Pricing Area -->  */}
      <div id="customers" className="bg-athens pricing-area">
        <div className="container pt-12 pt-lg-24 pb-12 pb-lg-6">
          <div className="row justify-content-center">
            <div
              className="col-xxl-10 col-lg-10 col-md-10 "
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              {/* <!-- section-title start --> */}
              <div className="section-title text-center mb-12 mb-lg-18 mb-lg-15 pb-lg-15 pb-0">
                <h2 className="mb-9">
                  Customers
                </h2>
                <p className="text-default-color text-left font-size-4 px-5 ">
                  Drive your innovation and ideas to successful business outcomes faster – Come collaborate with us! We are your trusted technology partner. Brainstorm with leading CTOs on best technology directions and options, consult on high level design, execution, and implementation. Utilize our global network of domain experts, proven product and system design experts and engineers. We are Your extended R&D and CTO team – E-CTO.com.
                </p>
                <p className="text-default-color text-left font-size-4 px-5 ">Reach out to us and share your idea. Get our expert feedback and engage with us.</p>
              </div>
              {/* <!-- section-title end --> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
