import React from "react";

import imgB1 from "../../assets/image/thumbnails/ais.png";
import imgB2 from "../../assets/image/thumbnails/ai.png";
import imgB3 from "../../assets/image/thumbnails/cv.png";
import imgB4 from "../../assets/image/thumbnails/iot.jpg";
import imgB5 from "../../assets/image/thumbnails/robotics.png";

const Technologies = () => {

  return (
    <>
      {/* <!-- FeaturedProviders Area -->  */}
      <section id="technologies" className="pt-12 pt-lg-24 pb-7 pb-lg-25">
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row justify-content-center mb-lg-16 mb-11">
            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 text-center">
              <h2 className="mb-6 mb-lg-7 text-black-2 font-size-10">
                Technologies
              </h2>
              <p className="px-xs-3 px-md-12 px-lg-8 px-xl-8 px-xxl-6 text-left font-size-4 mb-0">
              We integrate multiple technology skills, licensed IPs, COTS and custom development services across new and emerging
technologies
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
              <div className="col-md-10 font-weight-bold font-size-5 text-black-2">
                <div className="row">
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={imgB1}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Advanced Imaging Systems</span>
                  </div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={imgB2}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Artificial Intelligence</span>
                  </div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={imgB3}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Computer Vision</span></div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={imgB4}
                    className="align-self-center mr-4 mt-4"
                    alt=""
                    />
                    <span>Internet of Things</span></div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={imgB5}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Robotics, Sensors & Devices</span></div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Technologies;
