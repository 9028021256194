import React from "react";
import firebase from "gatsby-plugin-firebase";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing1/Hero";
import Industries from "../sections/landing1/Industries";
import Technologies from "../sections/landing1/Technologies";
import Affiliates from "../sections/landing1/Affiliates";
import Services from "../sections/landing1/Services";
import Customers from "../sections/landing1/Customers";
import BusinessModels from "../sections/landing1/BusinessModels";
import Blog from "../sections/landing1/Blog";

const IndexPage = () => {
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) 
  {
    const analytics = firebase.analytics();
  }

  return (
    <>
      <PageWrapper
        headerConfig={{
          bgClass: "dynamic-sticky-bg",
        }}
      >
        <Hero />
        <Industries />
        <Technologies />
        <Services />
        <Affiliates />
        <Customers />
        <BusinessModels />
        {/*
        <Blog />
        */}
      </PageWrapper>
    </>
  );
};
export default IndexPage;
