import React from "react";

const Services = () => {

  return (
    <>
      {/* <!-- FeaturedProviders Area -->  */}
      <section id="services" className="bg-athens pt-12 pt-lg-24 pb-7 pb-lg-25">
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row justify-content-center mb-lg-16 mb-11">
            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 text-center">
              <h2 className="mb-6 mb-lg-7 text-black-2 font-size-10">
                Services
              </h2>
              <p className="px-xs-3 px-md-12 px-lg-8 px-xl-8 px-xxl-6 text-left font-size-4 mb-0">
              E-CTO.com offers experienced and comprehensive technology strategy and engineering execution services. Our global network of researchers, domain experts enable your ideas and innovations to attain viable business outcomes. Our business models ensure confidentiality, IP protection and seamless collaboration with your internal teams.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
              <div className="col-11 font-size-6 text-black-2">
                <div className="row">
                  <div className="col-md-6 mb-12" style={{minHeight:"80px", alignItems:"center", borderLeft:"4px #f59930 solid"}}>
                    <span>Technical viability and Strategy for Idea execution</span>
                    <ul className="text-default-color" style={{fontSize:"16px"}}>
                      <li>Experienced CTOs on call basis for confidential product discussions</li>
                      <li>Connect with our inhouse and affiliated experts with your product management and engineering teams</li>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-12" style={{minHeight:"80px", alignItems:"center", borderLeft:"4px #f59930 solid"}}>
                    <span>Architecture & Design</span>
                    <ul className="text-default-color" style={{fontSize:"16px"}}>
                      <li>Principal level engineers for design recommendations and reviews</li>
                      <li>Iterative design, architecture proposals -reviewed and finalized with your senior teams</li>
                      <li>Niche and emergent technologies integration in highlevel designs</li>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-12" style={{minHeight:"80px", alignItems:"center", borderLeft:"4px #f59930 solid"}}>
                    <span>Software and Embedded Development</span>
                    <ul className="text-default-color" style={{fontSize:"16px"}}>
                      <li>Advanced and senior level engineers deployed in your location(s)</li>
                      <li>Agile development and stand up reviews with your tech teams</li>
                      <li>Predefined and scoped out projects execution in affiliates premises</li>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-12" style={{minHeight:"80px", alignItems:"center", borderLeft:"4px #f59930 solid"}}>
                    <span>Product Integration and Testing</span>
                    <ul className="text-default-color" style={{fontSize:"16px"}}>
                      <li>Test bench creation and automation</li>
                      <li>Use cases development validation and test sutomation</li>
                      <li>Advanced and technology specific testing at affiliates premises</li>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-12" style={{minHeight:"80px", alignItems:"center", borderLeft:"4px #f59930 solid"}}>
                    <span>Field deployment and customer integration</span>
                    <ul className="text-default-color" style={{fontSize:"16px"}}>
                      <li>Industry vertical experts for seamless customer integration</li>
                    </ul>
                  </div>
                  <div className="col-md-6 mb-12" style={{minHeight:"80px", alignItems:"center", borderLeft:"4px #f59930 solid"}}>
                    <span>Inhouse skills development</span>
                    <ul className="text-default-color" style={{fontSize:"16px"}}>
                      <li>Hands on training and skills upgradation of your employees in affiliates premises and shadowing of development experience</li>
                    </ul>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
