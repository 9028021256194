import React from "react";

import img1 from "../../assets/image/thumbnails/ae.png";
import img2 from "../../assets/image/thumbnails/ad.jpg";
import img3 from "../../assets/image/thumbnails/at.jpg";
import img4 from "../../assets/image/thumbnails/et.png";
import img5 from "../../assets/image/thumbnails/ft.png";
import img6 from "../../assets/image/thumbnails/hss.png";
import img7 from "../../assets/image/thumbnails/tmt.png";


const Industries = () => {

  return (
    <>
      {/* <!-- FeaturedProviders Area -->  */}
      <section id="industries" className="bg-athens pt-12 pt-lg-24 pb-7 pb-lg-25">
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row justify-content-center mb-lg-16 mb-11">
            <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 text-center">
              <h2 className="mb-6 mb-lg-7 text-black-2 font-size-10">
                Industries
              </h2>
              <p className="px-xs-3 px-md-12 px-lg-8 px-xl-8 px-xxl-6 text-left font-size-4 mb-0">
              We serve varied industries across globe with affiliated global research labs, empanelled technology experts,
              design houses of repute, software enterprises and startup ecosystem
              </p>
            </div>
          </div>
          {/* <!-- Section Title End --> */}
          <div className="row justify-content-center">
              <div className="col-md-10 font-weight-bold font-size-5 text-black-2">
                <div className="row">
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={img1}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Advanced Electronics</span>
                  </div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={img2}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Aerospace & Defence</span>
                  </div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={img3}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Automotive & Transportation</span>
                  </div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={img4}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Edutech</span>
                  </div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={img5}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Fintech</span>
                  </div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={img6}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Healthcare Systems & Services</span>
                  </div>
                  <div className="col-md-6 mt-8" style={{float:"left", display:"flex", alignItems:"center"}}>
                    <img width="25%"
                    src={img7}
                    className="align-self-center mr-3 mt-2"
                    alt=""
                    />
                    <span>Technology, Media & Telecommunications</span>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Industries;
