import React from "react";
import imgA1 from "../../assets/image/thumbnails/researchlabs.png";
import imgA2 from "../../assets/image/thumbnails/university.png";
import imgA3 from "../../assets/image/thumbnails/scholars.png";
import imgA4 from "../../assets/image/thumbnails/softwarehouses.png";

const Affiliates = () => {
  return (
    <>
      {/* <!-- Pricing Area -->  */}
      <div id="affiliates" className="pricing-area">
        <div className="container pt-12 pt-lg-24 pb-13 pb-lg-25">
          <div className="row justify-content-center">
            <div
              className="col-xxl-10 col-lg-10 col-md-10 "
              data-aos="fade-in"
              data-aos-duration="1000"
            >
              {/* <!-- section-title start --> */}
              <div className="section-title text-center mb-12 mb-lg-12 pb-lg-15 pb-0">
                <h2 className="mb-9">
                  Affiliates
                </h2>
                <p className="text-default-color text-left font-size-4 px-5 ">
                  We enable global corporations, technology start ups and Venture capital companies to be connected with Global R&D labs,
                  leading university research teams, elite PhD and post doctoral scholars, technology software corporations and Silicon Valley
                  incubators and startups that are relevant to your industries and technologies. E-cto.com will help customers acquire expertise
                  and resources to integrate new technologies and next-generation innovation in their future portfolio
                </p>
              </div>
              {/* <!-- section-title end --> */}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xxl-10 col-xl-11">
              <div className="row justify-content-center">
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{ minHeight: "370px" }}>
                      <img src={imgA1} className="card-img-top" style={{ backgroundColor: "#06213e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-8">
                          Alumni Scholars of Global Research Labs
                        </h6>
                      </div>
                      <div className="mt-8 text-dodger text-left pl-8 pr-8 ">
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Argonne National Laboratory
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Broad Institute of MIT and Harvard
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Brookhaven National Laboratory
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Centre National de la Recherche Scientifique CNRS
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Deutsche Elektronen-Synchrotron DESY
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          European Space Agency
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Forschungszentrum Jülich
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Fraunhofer Gesellschaft
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Institute of Physical and Chemical Research
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Japan Science & Technology Agency
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Karlsruhe Institute of Technology
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Lawrence Berkeley National Laboratory
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Los Alamos National Laboratory
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Max Planck Gesellschaft
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          National Aeronautics and Space Administration
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Oak Ridge National Laboratory Rand Corporation
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          US National Science Foundation
                        </span>{" "}<br />
                      </div>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{ minHeight: "370px" }}>
                      <img src={imgA2} className="card-img-top" style={{ backgroundColor: "#06215e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-8">
                          Scholar affiliates - Leading University Labs
                        </h6>
                      </div>
                      <div className="mt-8 text-dodger text-left pl-8 pr-8 ">
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Brown University
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          California Institute Of Technology
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Duke University
                        </span>{" "}<br/>
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Georgia Institute Of Technology
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Indian Institute Of Technology, Kanpur
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Indian Institute Of Science, Bangalore
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Johns Hopkins University
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Massachusetts Institute Of Technology
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Michigan State University
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Purdue University
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Stanford University
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Texas A & M University
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          University Of California At Los Angeles
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          University Of Washington
                        </span>{" "}<br />
                        <span className="font-size-4 text-smoke font-weight-normal">
                          Yale University
                        </span>{" "}<br />
                      </div>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{ minHeight: "200px" }}>
                      <img src={imgA3} className="card-img-top" style={{ backgroundColor: "#06215e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-8">
                          Elite PhD and Post Doctoral Scholars
                        </h6>
                      </div>
                      <h2 className="mt-8 text-dodger">
                        <span className="text-left pl-8 pr-8 font-size-4 text-smoke font-weight-normal">
                          Across technology domains empaneled scholars for contract assignments
                        </span>{" "}
                      </h2>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
                <div
                  className="col-lg-3 col-md-6 col-xs-9 "
                  data-aos="fade-left"
                  data-aos-duration="1000"
                >
                  {/* <!-- card start --> */}
                  <div className="card border-0 rounded-8 hover-shadow-hitgray mb-8">
                    {/* <!-- card-header start --> */}
                    <div className="bg-transparent border-hit-gray-opacity-5 text-center pb-8" style={{ minHeight: "200px" }}>
                      <img src={imgA4} className="card-img-top" style={{ backgroundColor: "#06215e" }} alt="..." />
                      <div className="pricing-title text-center">
                        <h6 className="font-weight-semibold font-size-5 text-black-2 pt-8">
                          Technology Software Corporations
                        </h6>
                      </div>
                      <h2 className="mt-8 text-dodger">
                        <span className="text-left pl-8 pr-8 font-size-4 text-smoke font-weight-normal">
                          Selected and proven software corporations to collaborate and execute projects
                        </span>{" "}
                      </h2>
                    </div>
                  </div>
                  {/* <!-- card end --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Affiliates;
